/* cyrillic-ext */
@font-face {
  font-family: '__Raleway_904c5e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/200388358b398524-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Raleway_904c5e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/34900c74a84112b6-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Raleway_904c5e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/10dadb2e82d03733-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Raleway_904c5e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/5f2068c3133468f5-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Raleway_904c5e';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/9bf67a161a796382-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Raleway_Fallback_904c5e';src: local("Arial");ascent-override: 90.51%;descent-override: 22.53%;line-gap-override: 0.00%;size-adjust: 103.86%
}.__className_904c5e {font-family: '__Raleway_904c5e', '__Raleway_Fallback_904c5e';font-style: normal
}.__variable_904c5e {--font-sans: '__Raleway_904c5e', '__Raleway_Fallback_904c5e'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Yeseva_One_a7f4b8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5661911c6845ef4b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Yeseva_One_a7f4b8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/86dbcbf4f9c4e425-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Yeseva_One_a7f4b8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/598f29af60f15512-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Yeseva_One_a7f4b8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/32b09ec4dd601a14-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Yeseva_One_a7f4b8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7253592f24e7de11-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Yeseva_One_Fallback_a7f4b8';src: local("Arial");ascent-override: 79.67%;descent-override: 20.90%;line-gap-override: 0.00%;size-adjust: 114.85%
}.__className_a7f4b8 {font-family: '__Yeseva_One_a7f4b8', '__Yeseva_One_Fallback_a7f4b8';font-weight: 400;font-style: normal
}.__variable_a7f4b8 {--font-display: '__Yeseva_One_a7f4b8', '__Yeseva_One_Fallback_a7f4b8'
}

